import IonIcon from '@reacticons/ionicons'
import React, { useEffect, useState } from 'react'
import { CheckBox } from 'src/components/global/CheckBox/CheckBox'
import { FormFieldInput } from 'src/components/global/ListingForm/FormFieldInput/FormFieldInput'
import FormFieldLabel from 'src/components/global/ListingForm/FormFieldLabel/FormFieldLabel'
import { apolloSdk } from 'src/graphql/apolloSdk'
import {
  INewsletterCategoryEntity,
  INewsletterSubscriptionInput,
  useSubmitNewsletterSubscriptionMutation,
} from 'src/graphql/generated/hooks'
import { useAuthenticated } from 'src/hooks/useAuthenticated'

import styles from './NewsletterForm.module.css'

interface NewsletterFormProps {
  onClose: () => void
}

export const NewsletterForm: React.FC<NewsletterFormProps> = ({ onClose }) => {
  const { session } = useAuthenticated()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [errorFirstNameMsg, setErrorFirstName] = useState('')
  const [errorLastNameMsg, setErrorLastName] = useState('')
  const [email, setEmail] = useState(session?.user.email || '')
  const [errorEmailMsg, setErrorEmailMsg] = useState('')
  const [companyName, setCompanyName] = useState(session?.user.companyName || '')
  const [errorCompanyNameMsg, setErrorCompanyNameMsg] = useState('')
  const [newsletterCategories, setNewsletterCategories] = useState<INewsletterCategoryEntity[]>([])
  const [subscribeNewsletterCategories, setSubscribeNewsletterCategories] = useState<INewsletterCategoryEntity[]>([])
  const [submitNewsletterSubscription] = useSubmitNewsletterSubscriptionMutation()

  useEffect(() => {
    apolloSdk.getNewsletterCategories().then((res) => {
      setNewsletterCategories(res.newsletterCategories?.data || [])
    })
  }, [])

  const onSelectNewsletterCategory = (checked: boolean, category: INewsletterCategoryEntity) => {
    if (checked) setSubscribeNewsletterCategories([...subscribeNewsletterCategories, category])
    else {
      const findIndex = subscribeNewsletterCategories.findIndex((item) => item.id === category.id)
      subscribeNewsletterCategories.splice(findIndex, 1)
      setSubscribeNewsletterCategories([...subscribeNewsletterCategories])
    }
  }

  const isSelectedNewsletterCategory = (category: INewsletterCategoryEntity) => {
    return subscribeNewsletterCategories.filter((item) => item.id === category.id).length !== 0
  }

  const onChangeFirstName = (value: string) => {
    setFirstName(value)
    setErrorFirstName('')
  }

  const onChangeLastName = (value: string) => {
    setLastName(value)
    setErrorLastName('')
  }

  const onChangeCompanyName = (value: string) => {
    setCompanyName(value)
    setErrorCompanyNameMsg('')
  }

  const onChangeEmail = (value: string) => {
    setEmail(value)
    setErrorEmailMsg('')
  }

  const onSubmit = async () => {
    if (!firstName) setErrorFirstName(' ')
    if (!lastName) setErrorLastName(' ')
    if (!email) setErrorEmailMsg(' ')
    if (!companyName) setErrorCompanyNameMsg(' ')
    if (!firstName || !lastName || !email || !companyName) return

    const req: INewsletterSubscriptionInput = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      companyName: companyName,
      categories: subscribeNewsletterCategories.map((category) => category.id || ''),
    }
    await submitNewsletterSubscription({ variables: { data: req } })
    onClose()
  }

  return (
    <div className="flex flex-row w-full">
      <div className={styles.modal}>
        <div className="flex text-[25px]">
          <span className="font-primary font-bold text-textdark opacity-70 text-[18px]">Subscribe to Insights</span>
          <a className="flex items-center ml-auto" href="" onClick={onClose}>
            <IonIcon name="close-outline" className="!h-[30px] !w-[30px] text-navy" />
          </a>
        </div>
        <div className="flex flex-row mt-3">
          <div className="w-full text-[0.875rem] font-primary font-regular text-darkgray mr-[1.25rem]">
            <FormFieldLabel label="First Name *" />
            <FormFieldInput
              id="firstName"
              className="text-[15px] font-primary"
              type="text"
              required={true}
              value={firstName}
              onChange={onChangeFirstName}
              errorMessage={errorFirstNameMsg}
            />
          </div>
          <div className="w-full text-[0.875rem] font-primary font-regular text-darkgray">
            <FormFieldLabel label="Last Name *" />
            <FormFieldInput
              id="lastName"
              className="text-[15px] font-primary"
              type="text"
              required={true}
              value={lastName}
              onChange={onChangeLastName}
              errorMessage={errorLastNameMsg}
            />
          </div>
        </div>
        <div className="pt-[10px] mt-3">
          <div className="text-[0.875rem] font-primary font-regular text-darkgray">
            <FormFieldLabel label="Business Email *" />
            <FormFieldInput
              id="businessEmail"
              className="text-[15px] font-primary"
              type="email"
              required
              value={email}
              onChange={onChangeEmail}
              errorMessage={errorEmailMsg}
            />
          </div>

          <div className="text-[0.875rem] font-primary font-regular text-darkgray mt-5">
            <FormFieldLabel label="Category" />
            {newsletterCategories.map((category) => (
              <CheckBox
                key={category.id}
                labelClassName="i-have-read-and-acce"
                label={category.attributes?.name || ''}
                onChanged={(checked) => onSelectNewsletterCategory(checked, category)}
                selected={isSelectedNewsletterCategory(category)}
                indeterminate={isSelectedNewsletterCategory(category)}
              />
            ))}
          </div>

          <div className="text-[0.875rem] font-primary font-regular text-darkgray mt-3">
            <FormFieldLabel label="Company name *" />
            <FormFieldInput
              id="companyName"
              className="text-[15px] font-primary"
              type="text"
              required
              value={companyName}
              onChange={onChangeCompanyName}
              errorMessage={errorCompanyNameMsg}
            />
          </div>

          <button
            className="h-[50px] w-[152px] rounded-[25px] bg-navy text-[#FFFFFF] font-secondary text-[14px] font-bold mt-7"
            onClick={onSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )
}
